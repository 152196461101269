import type { GenericFollowUs as TGenericFollowUs } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { SocialMedia } from 'components/SocialMedia';
import { ISocialMedia, IAssetComponent } from 'components/SocialMedia/types';

// deprecated - should be removed

interface IGenericFollowUs extends ISharedTemplateProps {
  templateProps: TGenericFollowUs;
}

export const GenericFollowUs = ({
  templateProps: { id, super: superText, title, socialMedia, middlegroundImage, foregroundImage },
}: IGenericFollowUs) => {
  return (
    <SocialMedia
      id={id}
      super={superText}
      title={title}
      socialMedia={socialMedia as unknown as ISocialMedia[]}
      middlegroundImage={middlegroundImage as unknown as IAssetComponent}
      foregroundImage={foregroundImage as unknown as IAssetComponent}
    />
  );
};
