import { Text as Paragraph, Title } from 'components/Typography';
import { Grid, GridRow, GridItem } from 'components/Grid';
import clsx from 'clsx';
import { SocialMediaLinks } from './components/SocialMediaLinks';
import { SocialMediaImages } from './components/SocialMediaImages';
import { ISocialMediaComponent } from './types';

export const SocialMedia = ({
  super: superText,
  title,
  socialMedia,
  middlegroundImage,
  foregroundImage,
}: ISocialMediaComponent) => {
  return (
    <>
      <div className="relative overflow-hidden h-auto py-20 bg-no-repeat bg-cover print:hide md:bg-center">
        <Grid>
          <GridRow columns={16}>
            <GridItem colSpan={5.5} className="row-start-2 md:row-start-1">
              <div className="flex flex-col h-full justify-center">
                <Paragraph className="text-darkgray opacity-70" tag="div" type="leadxs">
                  {superText}
                </Paragraph>
                <Title
                  className="w-4/5 md:w-full sm:mt-px md:mt-2.5 sm:mb-5 md:mb-30 text-gray-300"
                  tag="div"
                  type="sm"
                >
                  {title}
                </Title>
                {socialMedia?.length && (
                  <div
                    className={clsx(
                      'grid grid-cols-1',
                      socialMedia?.length! > 3 && 'md:grid-cols-2',
                    )}
                  >
                    <SocialMediaLinks socialMedia={socialMedia} />
                  </div>
                )}
              </div>
            </GridItem>
            <GridItem colSpan={5} colStart={7}>
              <SocialMediaImages
                foregroundImage={foregroundImage}
                middlegroundImage={middlegroundImage}
              />
            </GridItem>
          </GridRow>
        </Grid>
      </div>
    </>
  );
};
