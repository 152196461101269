import { ImagesService } from 'service/images/imagesService';
import NextImage from 'components/NextImage';
import { ISocialMediaComponent } from '../types';

export const SocialMediaImages = ({
  middlegroundImage,
  foregroundImage,
}: Pick<ISocialMediaComponent, 'middlegroundImage' | 'foregroundImage'>) => {
  const middlegroundImageUrl = middlegroundImage?.asset?.[0]?.url ?? '';
  const foregroundImageUrl = foregroundImage?.asset?.[0]?.url;
  return (
    <div className="w-full relative">
      {middlegroundImageUrl && (
        <div className="w-auto relative -ml-14 -mr-14 md:-bottom-16 md:left-48 md:top-0 z-10">
          <svg
            width="100%"
            height="auto"
            viewBox="0 0 1025 769"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <clipPath id="social-share-mask">
                <path
                  d="M946.767 596.694C960.62 676.398 907.404 752.21 827.909 766.027C791.555 772.345 756.001 764.604 726.792 746.719C706.037 734.01 680.484 723.099 654.596 717.923C628.712 712.748 602.448 713.299 580.31 723.574C387.903 812.874 160.818 731.504 73.0947 541.849C40.6197 471.639 31.7947 396.432 43.3207 325.016C49.7496 285.182 33.4793 241.627 18.3657 201.169L18.3365 201.091C13.9289 189.292 9.61886 177.754 5.98769 166.632C4.42435 161.843 3.18392 156.884 2.29586 151.774C-7.6901 94.3186 30.6709 39.6695 87.9745 29.71C103.328 27.0415 118.482 27.8517 132.635 31.5726C153.228 36.9868 176.022 42.7804 198.504 44.9734C220.985 47.1665 243.201 45.7647 262.626 36.7492C393.871 -24.1644 541.255 -5.66674 649.534 72.4442C678.792 93.5509 716.322 100.639 751.984 95.8273C768.786 93.5602 786.106 93.0272 803.731 94.4065C934.707 104.657 1032.88 216.627 1023.03 344.475C1019.36 392.171 1001.17 435.341 973.013 470.194C946.22 503.355 939.473 554.731 946.767 596.694Z"
                  stroke="black"
                />
              </clipPath>
            </defs>
            <image
              height="100%"
              width="100%"
              preserveAspectRatio="xMidYMid slice"
              xlinkHref={ImagesService.getImageUrlWithCompressionParams(
                middlegroundImageUrl,
                70,
                1025,
                undefined,
                false,
              )}
              clipPath="url(#social-share-mask)"
            />
          </svg>
        </div>
      )}
      {foregroundImageUrl && (
        <div className="absolute flex items-center -right-20 -bottom-4  w-9/12 h-9/12 md:right-auto md:bottom-auto md:top-14 md:w-83 md:h-83 md:left-10 z-20">
          <NextImage
            src={foregroundImageUrl}
            alt=""
            width={1280}
            height={800}
            className="object-contain"
          />
        </div>
      )}
    </div>
  );
};
